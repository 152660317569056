import { Checkbox, OptionsContainer } from "./styles";

import { Form } from "semantic-ui-react";
import React from "react";
import { Role } from "@global/types";
import { TextArea } from "../../styles/questionCommons";

const Dif = "—";

const MultipleOption: React.FC<IStep1Props> = ({
  question,
  currentAnswer,
  answers,
  handleAnswerChange,
  role,
}: IStep1Props) => {

  const handleAnswerChangeFilter = (
    questionName: string,
    questionAnswer: string,
    eventCode?: string
  ) => {
    if (eventCode && eventCode !== "Enter") return;

    if (!question.options.includes(questionAnswer)) {
      handleAnswerChange(questionName + "_other", questionAnswer);
      return;
    }

    let newAnswer;

    if (currentAnswer) {
      newAnswer = currentAnswer;
    } else {
      newAnswer = "";
    }

    if (newAnswer.includes(questionAnswer)) {
      newAnswer = newAnswer.replace(questionAnswer + Dif, "");
    } else {
      if (question.maxOptions) {
        if (newAnswer.split(Dif).length - 1 >= question.maxOptions) return;
      }
      newAnswer = newAnswer + questionAnswer + Dif;
    }

    handleAnswerChange(questionName, newAnswer);
  };

  return (
    <Form>
      <OptionsContainer>
        <div className="options">
          {question.options &&
            question.options.map((option) => (
              <Form.Field>
                <Checkbox
                  key={option+'checkbox'}
                  label={option}
                  name={question.uid}
                  value={option}
                  checked={currentAnswer && currentAnswer.includes(option)}
                  onChange={() =>
                    handleAnswerChangeFilter(question.uid, option)
                  }
                  onKeyDown={(e: KeyboardEvent) => {
                    handleAnswerChangeFilter(question.uid, option, e.code);
                  }}
                  role={role}
                />
              </Form.Field>
            ))}
        </div>

        {question.other && currentAnswer?.includes('Other—') && (
          <div className="otherOption">
            <TextArea
              type="text"
              name={question.uid + "_other"}
              value={answers[question.uid + "_other"] || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
              }}
              placeholder="Please specify"
            />
          </div>
        )}
        
      </OptionsContainer>
    </Form>
  );
};

export default MultipleOption;

interface IStep1Props {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
    options?: string[];
    other?: boolean;
    maxOptions?: number;
  };
  answers: { [key: string]: string | number };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  currentAnswer: string;
  role: Role;
}
