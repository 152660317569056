import { Role } from "@global/types";
import { Radio as SemanticRadio } from "semantic-ui-react";
import styled from "styled-components";

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 671px;
  & .options {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 3;
    div {
      display: flex;
      max-width: 800px;
      label {
        text-align: start;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
  & .otherOption {
    flex-grow: 3;
    flex: 2;
  }
`;

export const Radio = styled(SemanticRadio)<{ role: Role, isRadio: boolean }>`
 margin-bottom: 15px;
 & :hover {
  & label {
    & ::before {
      box-shadow: 0 0 5px 2px white !important;
    }
  }
}
 & label {
   color: white !important;
   width: fit-content !important;
   font-weight: 500 !important;
   line-height: 20px !important;
   padding-top: 2px !important;
   & ::before {
     background: transparent !important;
     border: 1px solid ${({role}) => role === Role.broker ? "#94D9FF" : "#D8A6FF"} !important;
     width: 20px !important;
     height: 20px !important;
   }
   & ::after {
     background: ${({role}) => role === Role.broker ? "#94D9FF" : "#D8A6FF"} !important;
     content: "sarasa" !important;
     top: -2px !important;
     left: -3px !important;
     width: 26px !important;
     height: 26px !important;
   }
 }
`;
