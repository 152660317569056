import {
  Body,
  FinishContainer,
  HeaderContainer,
  HeaderLine,
  SurveyLogoContainer,
  TextBg,
  TextMd,
  TextSm,
  ThanksLogoContainer,
  WellnetLogoContainer
} from "./styles";

import React from "react";
import { Role } from "@global/types";
import SurveyLogo from "@assets/SurveyLogo2024.png";
import ThanksLogo from "@assets/ThanksLogo2024.svg";
import WellnetLogo from "@assets/WellnetLogo";

interface IFinishProps {
  role: Role
}

const Finish = ({role}: IFinishProps) => {
  return (
    <FinishContainer>
      <Body>
      <HeaderContainer>
          <WellnetLogoContainer>
            <WellnetLogo />
          </WellnetLogoContainer>
          <HeaderLine />
          <SurveyLogoContainer>
            <img src={SurveyLogo} />
          </SurveyLogoContainer>
        </HeaderContainer>
        <ThanksLogoContainer>
            <img src={ThanksLogo} />
        </ThanksLogoContainer>
        <TextBg>We appreciate your input & insights.</TextBg>
        <TextMd>P.S. You are now *entered for the chance to win two tickets & 1-night stay to Taylor Swift’s Eras Tour in New Orleans. Good luck!</TextMd>
        <TextSm>*No purchase necessary. A winner will be selected among all 2024 Healthcare Trends Survey responses. The giveaway is for two tickets to one night of the Taylor Swift Eras Tour in New Orleans between 10/25 and 10/27 & 1/night hotel accommodations. Only one winner allotted. No specifications or guarantees.</TextSm>
      </Body>
    </FinishContainer>
  );
};

export default Finish;
