import background from "@assets/BackgroundThankYou2024.png";
import mobileBackground from "@assets/BackgroundMobile2024.jpg"
import styled from "styled-components";

export const FinishContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-content: center;
  @media (max-width: 1300px) {
    align-items: center;
    background-image: url(${mobileBackground});
  }
`;

export const Body = styled.div`
  width: 680px;
  margin: 0 auto;
  margin-left: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1650px) {
    margin-left: 200px;
  }
  @media (max-width: 1300px) {
    width: 70%;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
export const ThanksLogoContainer = styled.div`
  margin: 45px auto;
  display: flex;
  img {
    width: 6200px;
    max-width: 90%;
    @media (max-width: 400px) {
      width: 200px;
    }
  }
`;
export const SurveyLogoContainer = styled.div`
  img {
    width: auto;
    height: 70px;
    max-width: 25vw;
    @media (max-width: 600px) {
      width: 140px;
      object-fit: contain;
      max-width: 35vw;
      height: 100px;
    }
  }
`;
export const WellnetLogoContainer = styled.div`
svg {
  width: auto;
  height: 70px;
  max-width: 25vw;
  @media (max-width: 600px) {
    width: 140px;
    max-width: 35vw;
  }
}
`;
export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8%;
  
  @media (max-width: 600px) {
    width: auto;
    justify-content: center;
  }
`;


export const HeaderLine = styled.div`
  width: 2px;
  height: 80px;
  margin-left: 30px;
  margin-right: 30px;
  background: white;
`;

export const TextBg = styled.p`
  width: 100%;
  color: white;
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    text-align: left;
  }
`;
export const TextMd = styled.p`
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    text-align: left;
  }
`;
export const TextSm = styled.p`
  width: 100%;
  color: white;
  font-size: 14px;
  font-style: italic;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    text-align: left;
  }
`;
export const StepButton = styled.div`
  background: white;
  cursor: pointer;
  border: 2px solid white;
  font-family: "Montserrat";
  line-height: 1rem;
  font-weight: bold;
  color: #1772ed;
  width: auto;
  min-width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & :hover {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  & :focus {
    box-shadow: 0 0 5px 2px white !important;
  }
`;
