import {
  Body,
  BodyContainer,
  Bold,
  ButtonsContainer,
  LogoContainer,
  MainImage,
  RoleSelectorContainer,
  SurveyLogoContainer,
  Text,
  TimeToComplete,
  WellnetLogoContainer
} from "./styles";

import MainImageSource from "@assets/MainImage2024.png";
import React from "react";
import { Role } from "@global/types";
import RoleButton from "@components/RoleButton";
import SurveyLogo from "@assets/SurveyLogo2024.png";
import WellnetLogo from "@assets/WellnetLogo";

const RoleSelector: React.FC<IRoleSelectorProps> = ({ handleRoleSelected }) => {
  return (
    <RoleSelectorContainer>
      <BodyContainer>
        <Body>
          <LogoContainer>
            <WellnetLogoContainer>
              <WellnetLogo />
            </WellnetLogoContainer>
            <SurveyLogoContainer>
              <img src={SurveyLogo} />
            </SurveyLogoContainer>
          </LogoContainer>
          <TimeToComplete>
          Approximately 3-5 minutes to complete.
          </TimeToComplete>
          <Text>
          The insurance advisor & C-suite <span>relationship</span> is more important now than ever.
          <br/><br/>
          With <span>steep</span> healthcare cost increases, delayed inflation impact, expensive specialty drugs & growing demands for transparency, this survey will <span>unpack the needs</span> & priorities of growing companies.
          <br/><br/>
          <span>A first of its kind:</span> the Healthcare Trends Survey <span>compares the alignment</span> between the advisor & employer perspective.
          </Text>
        </Body>
        <ButtonsContainer>
          <RoleButton
            role={Role.broker}
            onClick={() => {
              handleRoleSelected(Role.broker);
            }}
          />

          <RoleButton
            role={Role.employer}
            onClick={() => {
              handleRoleSelected(Role.employer);
            }}
          />
        </ButtonsContainer>
      </BodyContainer>
      <MainImage src={MainImageSource} />
    </RoleSelectorContainer>
  );
};

export default RoleSelector;

interface IRoleSelectorProps {
  handleRoleSelected: (role: Role) => void;
}
