import React from "react";
import { Form } from "semantic-ui-react";
import { TextArea } from "../../styles/questionCommons";

const LongTextInput: React.FC<IStep1Props> = ({
  question,
  currentAnswer,
  handleAnswerChange,
}: IStep1Props) => {
  return (
    <Form>
      <TextArea
        type="text"
        name={question.uid}
        value={currentAnswer}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
        }}
        placeholder="Your answer ..."
        maxlength="500"
      />
    </Form>
  );
};

export default LongTextInput;

interface IStep1Props {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
  };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  currentAnswer: string;
}
