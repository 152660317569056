import {
  Bold,
  Button,
  Italic,
  LogoImage,
  MainWelcome,
  RemarkableSection,
  RemarkableText,
  RemarkableTitle,
  SubTitle,
  TabletImage,
  TextSide,
  Title,
  WelcomeContainer,
} from "./styles";

import Logo from "../../assets/WellNet-New-Logo_Horizontal.png";
import React from "react";
import Remarkable2 from "../../assets/Remarkable-title.png";
import Tablet from "../../assets/ReMarkable.png";

const Welcome: React.FC<IWelcomeProps> = ({ handleNext }: IWelcomeProps) => {
  return (
    <WelcomeContainer>
      <MainWelcome>
        <LogoImage src={Logo} alt="Wellnet Logo" />
        <Title> 2024 Self-Insured Survey </Title>
        <SubTitle>
          Contribute to the second annual survey on the State of Self-Funding
        </SubTitle>
        <Button type="button" onClick={handleNext}>
          START!
        </Button>
      </MainWelcome>
      <RemarkableSection>
        <TabletImage src={Tablet} alt="tablet" />
        <TextSide>
          <RemarkableTitle src={Remarkable2} alt="reMarkable 2 Logo" />
          <RemarkableText>
            We think the best advisors should have the latest tech.
            <br />
            <br />
            <Bold>Take the survey </Bold> &amp; enter to win 1 of 3 tablets on the upcoming survey livecast
            <Italic> - stay tuned for details!</Italic>
          </RemarkableText>
        </TextSide>
      </RemarkableSection>
    </WelcomeContainer>
  );
};

export default Welcome;

interface IWelcomeProps {
  handleNext: () => void;
}