export default [
    {
        "key": "Alabama",
        "text": "Alabama",
        "value": "Alabama",
    },
    {
        "key": "Alaska",
        "text": "Alaska",
        "value": "Alaska",
    },
    {
        "key": "American Samoa",
        "text": "American Samoa",
        "value": "American Samoa",
    },
    {
        "key": "Arizona",
        "text": "Arizona",
        "value": "Arizona",
    },
    {
        "key": "Arkansas",
        "text": "Arkansas",
        "value": "Arkansas",
    },
    {
        "key": "California",
        "text": "California",
        "value": "California",
    },
    {
        "key": "Colorado",
        "text": "Colorado",
        "value": "Colorado",
    },
    {
        "key": "Connecticut",
        "text": "Connecticut",
        "value": "Connecticut",
    },
    {
        "key": "Delaware",
        "text": "Delaware",
        "value": "Delaware",
    },
    {
        "key": "District Of Columbia",
        "text": "District Of Columbia",
        "value": "District Of Columbia",
    },
    {
        "key": "Florida",
        "text": "Florida",
        "value": "Florida",
    },
    {
        "key": "Georgia",
        "text": "Georgia",
        "value": "Georgia",
    },
    {
        "key": "Guam",
        "text": "Guam",
        "value": "Guam",
    },
    {
        "key": "Hawaii",
        "text": "Hawaii",
        "value": "Hawaii",
    },
    {
        "key": "Idaho",
        "text": "Idaho",
        "value": "Idaho",
    },
    {
        "key": "Illinois",
        "text": "Illinois",
        "value": "Illinois",
    },
    {
        "key": "Indiana",
        "text": "Indiana",
        "value": "Indiana",
    },
    {
        "key": "Iowa",
        "text": "Iowa",
        "value": "Iowa",
    },
    {
        "key": "Kansas",
        "text": "Kansas",
        "value": "Kansas",
    },
    {
        "key": "Kentucky",
        "text": "Kentucky",
        "value": "Kentucky",
    },
    {
        "key": "Louisiana",
        "text": "Louisiana",
        "value": "Louisiana",
    },
    {
        "key": "Maine",
        "text": "Maine",
        "value": "Maine",
    },
    {
        "key": "Maryland",
        "text": "Maryland",
        "value": "Maryland",
    },
    {
        "key": "Massachusetts",
        "text": "Massachusetts",
        "value": "Massachusetts",
    },
    {
        "key": "Michigan",
        "text": "Michigan",
        "value": "Michigan",
    },
    {
        "key": "Minnesota",
        "text": "Minnesota",
        "value": "Minnesota",
    },
    {
        "key": "Mississippi",
        "text": "Mississippi",
        "value": "Mississippi",
    },
    {
        "key": "Missouri",
        "text": "Missouri",
        "value": "Missouri",
    },
    {
        "key": "Montana",
        "text": "Montana",
        "value": "Montana",
    },
    {
        "key": "Nebraska",
        "text": "Nebraska",
        "value": "Nebraska",
    },
    {
        "key": "Nevada",
        "text": "Nevada",
        "value": "Nevada",
    },
    {
        "key": "New Hampshire",
        "text": "New Hampshire",
        "value": "New Hampshire",
    },
    {
        "key": "New Jersey",
        "text": "New Jersey",
        "value": "New Jersey",
    },
    {
        "key": "New Mexico",
        "text": "New Mexico",
        "value": "New Mexico",
    },
    {
        "key": "New York",
        "text": "New York",
        "value": "New York"
    },
    {
        "key": "North Carolina",
        "text": "North Carolina",
        "value": "North Carolina"
    },
    {
        "key": "North Dakota",
        "text": "North Dakota",
        "value": "North Dakota",
    },
    {
        "key": "Ohio",
        "text": "Ohio",
        "value": "Ohio",
    },
    {
        "key": "Oklahoma",
        "text": "Oklahoma",
        "value": "Oklahoma",
    },
    {
        "key": "Oregon",
        "text": "Oregon",
        "value": "Oregon",
    },
    {
        "key": "Palau",
        "text": "Palau",
        "value": "Palau",
    },
    {
        "key": "Pennsylvania",
        "text": "Pennsylvania",
        "value": "Pennsylvania",
    },
    {
        "key": "Puerto Rico",
        "text": "Puerto Rico",
        "value": "Puerto Rico",
    },
    {
        "key": "Rhode Island",
        "text": "Rhode Island",
        "value": "Rhode Island",
    },
    {
        "key": "South Carolina",
        "text": "South Carolina",
        "value": "South Carolina",
    },
    {
        "key": "South Dakota",
        "text": "South Dakota",
        "value": "South Dakota",
    },
    {
        "key": "Tennessee",
        "text": "Tennessee",
        "value": "Tennessee",
    },
    {
        "key": "Texas",
        "text": "Texas",
        "value": "Texas",
    },
    {
        "key": "Utah",
        "text": "Utah",
        "value": "Utah",
    },
    {
        "key": "Vermont",
        "text": "Vermont",
        "value": "Vermont",
    },
    {
        "key": "Virgin Islands",
        "text": "Virgin Islands",
        "value": "Virgin Islands",
    },
    {
        "key": "Virginia",
        "text": "Virginia",
        "value": "Virginia",
    },
    {
        "key": "Washington",
        "text": "Washington",
        "value": "Washington",
    },
    {
        "key": "West Virginia",
        "text": "West Virginia",
        "value": "West Virginia",
    },
    {
        "key": "Wisconsin",
        "text": "Wisconsin",
        "value": "Wisconsin",
    },
    {
        "key": "Wyoming",
        "text": "Wyoming",
        "value": "Wyoming",
    }
]