import { Role } from "@global/types";
import { Checkbox as SemanticCheckbox } from "semantic-ui-react";
import styled from "styled-components";

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  max-width: 671px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  & .options {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 3;
    div {
      display: flex;
      max-width: 800px;
      label {
        text-align: start;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
  & .otherOption {
    flex-grow: 3;
    flex: 2;
  }
`;

export const Checkbox = styled(SemanticCheckbox)<{ role: Role, isCheckbox: boolean }>`

  margin-bottom: 15px;
  & :hover {
    & label {
      & ::before {
        box-shadow: 0 0 5px 2px white !important;
      }
    }
  }
  & label {
    color: white !important;
    width: fit-content !important;
    font-weight: 500 !important;
    & ::before {
      border-radius: 0 !important;
      border: 1px white;
      background: transparent !important;
      width: 20px !important;
      height: 20px !important;
    }
    & ::after {
      background:
      ${({ role }) => (role === Role.broker ? "#6b2600" : "#A81BEA")} !important;
      filter:
      ${({ role }) => (role === Role.broker ? "invert()" : "none")} !important;
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjE1OCIgaGVpZ2h0PSI4LjE1OCI+PGcgZGF0YS1uYW1lPSJHcm91cCA3NyI+PHBhdGggZGF0YS1uYW1lPSJSZWN0YW5nbGUgNDYwIiBmaWxsPSJub25lIiBkPSJNMCAwaDguMTU4djguMTU4SDB6Ii8+PHBhdGggZD0iTTMuMjM1IDYuNjQ2IDAgMy40MTFsLjY1Ni0uNjU2IDIuNTc5IDIuNTMyTDcuNSAxLjAybC42NTYuNjU2WiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=) !important;
      border-radius: 0;
      top: 0 !important;
      left: 0 !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
`;
