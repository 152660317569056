import React from "react";
import { SvgProps } from "@global/types";

const WellnetLogo = ({ width, height }: SvgProps) => {
  return (
    <svg
      id="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 215.967 56.366"
      width={width}
      height={height}
    >
      <g id="Capa_1-2">
        <g>
          <path
            d="m209.365,26.968h-5.073v-7.033h-6.558v-4.77h6.558v-7.033h5.073v7.033h6.602v4.77h-6.602v7.033Zm-3.668-1.404h2.264v-7.033h6.601v-1.961h-6.601v-7.033h-2.264v7.033h-6.558v1.961h6.558v7.033Z"
            fill="#fff"
          />
          <g>
            <path
              d="m72.309,42.002l-1.687-7.725-3.578-15.247c-.259-1.074-.507-1.713-.742-1.917-.235-.204-1.001-.38-2.299-.528v-1.113c2.076.087,3.646.13,4.709.13.952,0,2.559-.043,4.82-.13v1.113c-1.631.062-2.565.154-2.8.278-.161.087-.241.259-.241.519,0,.322.075.871.223,1.649.408,2.211.68,3.612.816,4.205l2.915,12.828,4.577-11.399c1.198-2.99,2.378-6.147,3.54-9.471h1.186c1.137,3.509,2.158,6.518,3.06,9.026l4.359,12.084,3.154-11.05c.556-1.975,1.074-4.032,1.556-6.169.222-.975.333-1.537.333-1.686,0-.259-.108-.447-.324-.565-.216-.117-1.202-.2-2.956-.25v-1.113c1.903.087,3.224.13,3.966.13.766,0,2.087-.043,3.964-.13v1.113c-.853.012-1.421.099-1.705.259-.285.161-.519.451-.705.871-.185.42-.704,2.05-1.557,4.891l-1.909,6.244c-.185.617-.575,2.019-1.168,4.205l-2.392,8.948h-1.891c-1.372-4.078-2.435-7.144-3.188-9.195l-4.227-11.44-3.69,9.159-2.318,6.063c-.322.829-.596,1.567-.825,2.216-.229.649-.596,1.715-1.103,3.198h-1.873Z"
              fill="#fff"
            />
            <path
              d="m113.5,38.92l-.593,1.335c-.853.533-1.477.886-1.873,1.059-.581.26-1.245.48-1.993.66-.748.179-1.536.269-2.364.269-1.632,0-3.108-.34-4.431-1.019-1.323-.68-2.352-1.697-3.087-3.05-.735-1.353-1.103-2.938-1.103-4.756,0-1.421.195-2.703.584-3.847.389-1.143.831-1.987,1.326-2.53.359-.396.915-.85,1.669-1.363.754-.513,1.502-.918,2.243-1.214.976-.408,2.033-.612,3.17-.612,1.384,0,2.636.316,3.754.946,1.118.63,1.937,1.489,2.457,2.577.519,1.088.779,2.318.779,3.69,0,.346-.031.767-.093,1.261-.889.173-1.685.284-2.388.333-1.333.099-2.666.148-3.999.148h-5.942c.025,1.772.331,3.206.918,4.303.587,1.096,1.397,1.905,2.429,2.425,1.032.52,2.104.78,3.217.78.753,0,1.505-.122,2.252-.367.748-.244,1.671-.692,2.772-1.343l.297.315Zm-11.884-7.578c.346.037,1.001.074,1.965.112,1.842.062,3.108.092,3.801.092,1.644,0,2.726-.031,3.245-.092.012-.297.018-.525.018-.686,0-1.953-.395-3.387-1.186-4.302s-1.848-1.372-3.17-1.372c-1.397,0-2.521.501-3.374,1.502-.853,1.001-1.286,2.583-1.298,4.746Z"
              fill="#fff"
            />
            <path
              d="m115.295,41.798v-1.057l1.743-.018c.272-.013.479-.059.621-.139.142-.08.25-.182.325-.305.136-.247.216-.686.241-1.315.012-.161.055-1.933.13-5.317v-7.688c0-2.062-.031-4.193-.093-6.392-.05-1.482-.118-2.309-.205-2.482-.062-.123-.162-.21-.298-.259-.187-.087-1.045-.13-2.574-.13v-1.094c2.793-.346,4.734-.779,5.821-1.298.161-.074.284-.111.371-.111.086,0,.154.028.204.084.049.055.074.133.074.232,0,.087-.012.21-.037.37l-.019.612c-.062,2.211-.099,4.094-.111,5.65l-.037,2.89v9.616c.052,3.212.083,4.947.093,5.206.012.617.087,1.069.224,1.353.05.111.124.207.224.287s.249.145.448.194c.087.013.777.031,2.071.056v1.057c-1.681-.086-3.158-.13-4.431-.13-1.211,0-2.806.043-4.783.13Z"
              fill="#fff"
            />
            <path
              d="m125.395,41.798v-1.057l1.743-.018c.272-.013.479-.059.621-.139.142-.08.25-.182.324-.305.136-.247.216-.686.241-1.315.012-.161.056-1.933.13-5.317v-7.688c0-2.062-.031-4.193-.093-6.392-.05-1.482-.118-2.309-.205-2.482-.062-.123-.162-.21-.298-.259-.187-.087-1.044-.13-2.574-.13v-1.094c2.793-.346,4.734-.779,5.821-1.298.16-.074.284-.111.371-.111s.154.028.204.084c.049.055.074.133.074.232,0,.087-.013.21-.037.37l-.018.612c-.062,2.211-.099,4.094-.111,5.65l-.037,2.89v9.616c.051,3.212.082,4.947.093,5.206.012.617.087,1.069.224,1.353.049.111.124.207.224.287.1.08.249.145.448.194.087.013.777.031,2.071.056v1.057c-1.681-.086-3.158-.13-4.431-.13-1.212,0-2.806.043-4.783.13Z"
              fill="#fff"
            />
            <path
              d="m135.607,41.798v-1.186c1.305-.012,2.163-.111,2.573-.296.174-.074.299-.191.374-.352.124-.247.211-.894.26-1.942.062-1.628.093-3.034.093-4.218v-15.075c0-.407-.025-.672-.074-.795-.099-.222-.322-.488-.667-.795-.223-.197-.479-.324-.77-.379-.29-.056-.936-.09-1.937-.102v-1.186c2.136.049,3.479.074,4.029.074.933,0,1.861-.025,2.782-.074,1.037,1.369,1.8,2.379,2.288,3.032l3.788,4.715,5.527,6.73c1.769,2.145,3.191,3.833,4.264,5.066.683.813,1.257,1.455,1.72,1.923v-13.502c0-1.206-.043-2.622-.13-4.247-.049-1.022-.13-1.656-.241-1.902-.086-.16-.216-.277-.389-.352-.408-.171-1.255-.264-2.54-.277v-1.186c1.459.049,2.936.074,4.431.074,1.409,0,2.688-.025,3.838-.074v1.186c-1.293.012-2.145.105-2.555.278-.174.074-.298.198-.373.37-.125.235-.212.883-.261,1.944-.062,1.629-.093,3.03-.093,4.202v8.905c0,1.802.031,5.166.093,10.09l-1.91-.556-.445-.093c-.074-.025-.143-.062-.204-.111-.087-.062-.322-.312-.705-.749-1.026-1.175-1.78-2.062-2.262-2.661l-2.392-3.055-13.144-15.874v14.369c0,1.224.043,2.667.13,4.327.049,1.037.13,1.674.241,1.911.086.175.216.3.39.375.407.175,1.254.268,2.54.281v1.186c-1.088-.086-2.287-.13-3.596-.13-1.434,0-2.991.043-4.672.13Z"
              fill="#fff"
            />
            <path
              d="m181.544,38.92l-.593,1.335c-.853.533-1.477.886-1.873,1.059-.581.26-1.245.48-1.993.66-.748.179-1.536.269-2.364.269-1.631,0-3.109-.34-4.431-1.019-1.323-.68-2.352-1.697-3.087-3.05-.736-1.353-1.103-2.938-1.103-4.756,0-1.421.195-2.703.584-3.847.39-1.143.831-1.987,1.326-2.53.358-.396.914-.85,1.668-1.363.754-.513,1.502-.918,2.244-1.214.976-.408,2.033-.612,3.17-.612,1.384,0,2.636.316,3.754.946,1.118.63,1.937,1.489,2.457,2.577.519,1.088.779,2.318.779,3.69,0,.346-.031.767-.093,1.261-.889.173-1.685.284-2.388.333-1.333.099-2.666.148-3.998.148h-5.942c.025,1.772.331,3.206.917,4.303.587,1.096,1.397,1.905,2.429,2.425,1.032.52,2.104.78,3.217.78.753,0,1.504-.122,2.252-.367.748-.244,1.671-.692,2.772-1.343l.296.315Zm-11.883-7.578c.346.037,1.001.074,1.965.112,1.841.062,3.108.092,3.801.092,1.644,0,2.726-.031,3.244-.092.012-.297.019-.525.019-.686,0-1.953-.396-3.387-1.187-4.302-.791-.915-1.848-1.372-3.17-1.372-1.397,0-2.521.501-3.375,1.502-.853,1.001-1.285,2.583-1.297,4.746Z"
              fill="#fff"
            />
            <path
              d="m183.246,27.263v-.779c1.189-.469,2.135-.89,2.836-1.261v-5.636c1.286-.445,2.33-.921,3.133-1.428l.445.371c-.136,1.026-.284,3.288-.445,6.786.989.013,1.582.019,1.78.019.112,0,.748-.012,1.909-.037.643-.012,1.125-.038,1.446-.075l.13.13-.39,1.724c-.778-.012-1.495-.019-2.15-.019s-1.564.006-2.726.019l-.093,7.546c-.037,1.841-.025,2.942.037,3.3.061.358.178.67.351.936.172.266.397.479.674.64.277.161.748.285,1.413.371.505,0,.914-.021,1.228-.065.314-.043.712-.151,1.192-.324l.221.723c-.58.457-1.142.933-1.685,1.427-.469.174-.908.288-1.315.343-.407.056-.876.084-1.407.084-.654,0-1.176-.074-1.565-.222-.389-.148-.744-.355-1.065-.621-.321-.266-.588-.602-.801-1.01-.213-.408-.35-1.051-.413-1.928.012-.841.037-1.762.074-2.763.012-.197.018-.414.018-.649v-7.694h-.76c-.618,0-1.31.031-2.077.093Z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="m0,0v56.366h56.366V0H0Zm54.029,2.332v51.697H2.333V2.332h51.696Z"
              fill="#fff"
            />
            <polygon
              points="47.837 16.961 40.885 16.96 35.984 30.344 31.152 16.96 24.494 16.96 15.705 41.205 22.699 41.205 27.599 27.821 32.433 41.205 38.983 41.205 47.837 16.961"
              fill="#fff"
            />
            <polygon
              points="7.979 16.96 17.713 16.96 12.813 30.344 7.979 16.96"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WellnetLogo;
