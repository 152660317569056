import { Role } from "@global/types";
import brokerBackground from "@assets/BrokerBackground2024.jpg";
import employerBackground from "@assets/EmployerBackground2024.jpg";
import generalBackground from "@assets/SelectorBackground2024.png";
import mobileBackground from '@assets/BackgroundMobile2024.jpg'
import styled from "styled-components";

export const Background = styled.div<QuestionsStylesProps>`
  background-image: ${({ role }) =>
    role === Role.broker
      ? `url(${brokerBackground})`
      : `url(${employerBackground})`};
 position: fixed;
 width: 100vw;
 height: 100vh;
 background-repeat: no-repeat;
 background-size: cover;
 z-index: 50;
  @media (max-width: 400px) {
    background-image: url(${mobileBackground});
  }
`;

export const QuestionLayoutContainer = styled.div<QuestionsStylesProps>`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 400px) {
    background-image: url(${mobileBackground});
  }
`;

export const StickersImage = styled.img`
  position: fixed;
  bottom: 0;
  width: 20vw;
  right: 0;
`;

export const QuestionBody = styled.div`
  padding-bottom: 30px;
  position: relative;
  z-index: 100;
  @media (max-width: 600px) {
    padding: 40px 10px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8%;
`;

export const WellnetLogoContainer = styled.div`
svg {
height: 60px;
display: flex;
align-items: center;
justify-content: center;
width: 150px;
  @media (max-width: 400px) {
    width: 110px;
  }
}
`;

export const SurveyLogoContainer = styled.div`
  img {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    @media (max-width: 400px) {
      width: 150px;
    }
  }
`;

export const HeaderLine = styled.div`
  width: 2px;
  height: 65px;
  margin-left: 20px;
  margin-right: 20px;
  background: white;
`;
export const QuestionSection = styled.div<QuestionsStylesProps>`
  color: ${({ role }) => (role === Role.broker ? "#94D9FF" : "#F550FD")};
  font-weight: bold;
  font-size: 20px;
  width: fit-content;
  margin-top: 20px;
`;
export const QuestionInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  @media (max-width: 400px) {
  }
  
`;

export const ValidationNote = styled.p`
  width: 100%;
  margin: 20px 0 10px 0;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  span {
    font-weight: 600;
  }
`;

export const StepsCounter = styled.p`
  color: white;
  white-space: nowrap;
  font-size: 40px;
  display: flex;
  flex-direction: row;
`;

export const AclarationText = styled.p`
  color: ${({ role }) => (role === Role.broker ? "#D8A6FF" : "#88E9FF")};
  font-size: 18px;
  font-style: italic;
  display: flex;
  font-weight: 500;
  margin-top: 30px;
  text-align: left;
`

export const CurrentStep = styled.p<QuestionsStylesProps>`
  color: ${({ role }) => (role === Role.broker ? "#94D9FF" : "#D8A6FF")};
  font-weight: 900;
  span {
    color: white;
  }
`;


export const FinalText = styled.p<QuestionsStylesProps>`
  color: ${({ role }) => (role === Role.broker ? "#94D9FF" : "#D8A6FF")};
  white-space: normal;
  text-align: left;
  font-size: 28px;
  margin-bottom: -10px;
  font-weight: 900;
  span {
    color: white;
  }
`;

export const QuestionTextContainer = styled.div<{finish: boolean}>`
  margin-bottom: ${({finish}) => finish ? '0' : '40px'};
  margin-top: 10px;
`

export const RoleSelected = styled.div<QuestionsStylesProps>`
  padding: 11px 17px;
  border: 1px solid
    ${({ role }) => (role === Role.broker ? "#1772ED" : "#D8A6FF")};
  font-size: 14px;
  color: white;
  font-family: "Montserrat";
  width: fit-content;
  margin-right: 20px;
  box-shadow: 0px 0px 3px
    ${({ role }) => (role === Role.broker ? "#a81bea" : "#D8A6FF")};
`;

interface QuestionsStylesProps {
  role: Role;
}
