import background from "@assets/SelectorBackground2024.png";
import styled from "styled-components";

export const RoleSelectorContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    place-content: center;
}
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  margin-right: 20%;
  align-items: center;
  @media (max-width: 1440px) {
    margin-top: 7%;
  }
  @media (max-width: 1045px) {
    margin-right: 0%;
    flex-direction: column;
  }
  @media (max-width: 657px) {
    width: 300px;
  }
`;

export const MainImage = styled.img`
  width: 40%;
  right: 0;
  position: absolute;
  bottom: 0;
  @media (max-width: 1750px) {
    width: 35%;
    bottom: 15%;
  }
  @media (max-width: 1450px) {
    width: 30%;
    bottom: 20%;
  }
  @media (max-width: 1250px) {
    display: none;
  }
  @media (max-height: 800px) {
    bottom: 0%; 
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 250px;
  justify-content: end;
  margin-left: 70px;
  @media (max-width: 1045px) {
    margin-top: 50px;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
    margin-left: 0;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 415px;
  align-items: flex-start;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  @media (max-width: 1045px) {
    align-items: center;
  }
  img{
    object-fit: contain;
  }
`;

export const WellnetLogoContainer = styled.div`
  width: 195px;
  @media (max-width: 657px) {
    width: 150px;
  }
  img{
    object-fit: contain;
  }
`;

export const Bold = styled.div`
  font-weight: 800;
  display: inline;
  }
`;

export const SurveyLogoContainer = styled.div`
  display: flex;
  img {
    object-fit: 'contain';
    height: fit-content;
    width: 385px;
    height: auto;
    @media (max-width: 1045px) {
      width: 350px;
      height: auto;
    }
    @media (max-width: 657px) {
      width: 250px;
    }
  }
`;

export const Text = styled.p`
  color: white;
  font-size: 18px;
  font: 16px/26px "Montserrat";
  max-width: 580px;
  margin-top: 35px;
  font-weight: 500;
  text-align: start;
  
  span {
    font-weight: 700;
  }

`;

export const TimeToComplete = styled.div`
  color: #88E9FF;
  font-size: 18px;
  font-weight: 600;
  display: inline;
  text-align: left;
  margin-top: 35px;
  

  @media (max-width: 1045px) {
    align-self: center;
  }
`;
