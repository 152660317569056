import { OptionsContainer, Radio } from "./styles";
import React, { useState } from "react";

import { Form } from "semantic-ui-react";
import { Role } from "@global/types";
import { TextArea } from "../../styles/questionCommons";

const SingleOption: React.FC<IStep1Props> = ({
  question,
  currentAnswer,
  handleAnswerChange,
  role,
}: IStep1Props) => {
  const [otherSelected, setOtherSelected] = useState(false)
  const textareaFilter = () => {
    if (question.options.includes(currentAnswer)) return "";
    return currentAnswer;
  };

  const handleKeyDown = (
    questionUid: string,
    option: string,
    eventCode?: string,
    isOther: boolean = false
  ) => {
    if (eventCode && eventCode !== "Enter") return;

    handleSelection(questionUid, option, isOther);
  };

  const handleSelection = (questionUid: string, option: string, isOther: boolean = false) => {
    handleAnswerChange(questionUid, option)
    setOtherSelected(isOther)
  }


  return (
    <Form>
      <OptionsContainer>
        <div className="options">
          {question.options &&
            question.options.map((option) => {
              if (option === 'Other') return
              return(
              <Radio
                isRadio={true}
                key={option}
                label={option}
                name={question.uid}
                value={option}
                checked={currentAnswer === option}
                onClick={() => handleSelection(question.uid, option)}
                onKeyDown={(e: KeyboardEvent) => {
                  handleKeyDown(question.uid, option, e.code);
                }}
                role={role}
              />
            )})}
        </div>

        {question.other &&(
          <>
          <Radio
                isRadio={true}
                key={'Other'}
                label={'Other'}
                name={question.uid}
                value={'Other'}
                checked={otherSelected}
                onClick={() => handleSelection(question.uid, 'Other', true)}
                onKeyDown={(e: KeyboardEvent) => {
                  handleKeyDown(question.uid, 'Other', e.code, true);
                }}
                role={role}
              />
          <div className="otherOption">
            {otherSelected &&
              <TextArea
                type="text"
                name={question.uid}
                value={textareaFilter()}
                disabled={false}
                onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                  handleSelection(e.currentTarget.name, e.currentTarget.value, true);
                }}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  handleSelection(e.currentTarget.name, e.currentTarget.value, true);
                }}
                placeholder="Please specify"
              />
            }
          </div>
          </>
        )}
      </OptionsContainer>
    </Form>
  );
};

export default SingleOption;

interface IStep1Props {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
    options?: string[];
    other?: boolean;
  };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  currentAnswer: string;
  role: Role;
}
