import { Checkbox, OptionsContainer } from "./styles";

import { Form } from "semantic-ui-react";
import React from "react";
import { Role } from "@global/types";
import { TextArea } from "../../styles/questionCommons";

const Dif = "—";

const RankedOption: React.FC<IStep1Props> = ({
  question,
  currentAnswer,
  answers,
  role,
  handleAnswerChange,
}) => {
  const currentAnswerArray = currentAnswer ? currentAnswer.split(Dif) : [];

  const handleAnswerChangeFilter = (
    questionName: string,
    questionAnswer: string,
    eventCode?: string
  ) => {
    if (eventCode && eventCode !== "Enter") return;

    if (!question.options.includes(questionAnswer)) {
      handleAnswerChange(questionName + "_other", questionAnswer);
      return;
    }

    let newAnswer;

    if (currentAnswer) {
      newAnswer = currentAnswer;
    } else {
      newAnswer = "";
    }

    if (newAnswer.includes(questionAnswer)) {
      newAnswer = newAnswer.replace(questionAnswer + Dif, "");
    } else {
      if (question.maxOptions) {
        if (newAnswer.split(Dif).length - 1 >= question.maxOptions) return;
      }
      newAnswer = newAnswer + questionAnswer + Dif;
    }

    handleAnswerChange(questionName, newAnswer);
  };

  const handleTextAreaChange = (targetName: string, targetValue: string) => {
    if (!targetValue && currentAnswer && currentAnswer.includes("Other")) {
      handleAnswerChangeFilter(question.uid, "Other");
    }
    if (targetValue && currentAnswer && !currentAnswer.includes("Other")) {
      handleAnswerChangeFilter(question.uid, "Other");
    }

    handleAnswerChange(targetName, targetValue);
  };

  const currentRanking = (option: string) => {
    if (currentAnswerArray.indexOf(option) === -1) return 0;

    return currentAnswerArray.indexOf(option) + 1;
  };

  return (
    <Form>
      <OptionsContainer>
        <div className="options">
          {question.options &&
            question.options.map((option) => {
              if (option === "Other") return;
              return (
                <Form.Field>
                  <Checkbox
                    key={option}
                    label={`${currentRanking(option)} - ${option}`}
                    name={question.uid}
                    value={option}
                    checked={currentAnswer && currentAnswer.includes(option)}
                    onChange={() =>
                      handleAnswerChangeFilter(question.uid, option)
                    }
                    onKeyDown={(e: KeyboardEvent) => {
                      handleAnswerChangeFilter(question.uid, option, e.code);
                    }}
                    role={role}
                  />
                </Form.Field>
              );
            })}
        </div>

        {question.other &&(
          <div className="otherOption">
            <Checkbox
              key={"Other"}
              label={`${currentRanking("Other")} - ${"Other"}`}
              name={question.uid}
              value={"Other"}
              checked={currentAnswer && currentAnswer.includes("Other")}
              onChange={() => handleAnswerChangeFilter(question.uid, "Other")}
              onKeyDown={(e: KeyboardEvent) => {
                handleAnswerChangeFilter(question.uid, "Other", e.code);
              }}
              role={role}
            />
            <TextArea
              type="text"
              name={question.uid + "_other"}
              value={answers[question.uid + "_other"] || ""}
              disabled={!currentAnswer || !currentAnswer.includes("Other")}
              onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                handleTextAreaChange(
                  e.currentTarget.name,
                  e.currentTarget.value
                );
              }}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleTextAreaChange(
                  e.currentTarget.name,
                  e.currentTarget.value
                );
              }}
              placeholder="Please specify"
            />
          </div>
        )}
      </OptionsContainer>
    </Form>
  );
};

export default RankedOption;

interface IStep1Props {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
    options?: string[];
    other?: boolean;
    maxOptions?: number;
  };
  currentAnswer: string;
  answers: { [key: string]: string | number };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  role: Role;
}
