import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

export const ModalBackdrop = styled.div<IModalProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
`;
export const ModalContainer = styled.div`
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px) brightness(60%);
  position: absolute;
  width: fit-content;
  max-width: 85vw;
  height: fit-content;
  margin: auto;
  align-self: center;
  border-radius: 15px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 1);
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Montserrat";
  @media (max-width: 450px) {
    border-radius: 7px;
  }
`;
export const ModalWarning = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

export const Spinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: auto;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const CloseButton = styled.div`
  width: fit-content;
  align-self: end;
  margin-bottom: 7px;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 26px;
  top: 100px;
  right: 100px;
  @media (max-width: 700px) {
    top: 50px;
    right: 50px;
  }
`;

interface IModalProps {
  active: boolean;
}
