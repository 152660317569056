import {
  ErrorText,
  Input,
  LabelText
} from "../../styles/questionCommons";

import { Dropdown } from "semantic-ui-react";
import React from "react";
import { Role } from "@global/types";
import { isValidEmail } from "helpers/regex";
import statesList from "../../helpers/statesList";

const statesOptions = statesList;

const companySizeOptions = [
  {
    key: "Under 100",
    text: "Under 100",
    value: "Under 100",
  },
  {
    key: "100-250",
    text: "100-250",
    value: "100-250",
  },
  {
    key: "250-500",
    text: "250-500",
    value: "250-500",
  },
  {
    key: "500-1000",
    text: "500-1000",
    value: "500-10000",
  },
  {
    key: "1000+",
    text: "1000+",
    value: "1000+",
  },
];

const bookProfileOptions = [
  {
    key: "250,000-500,00",
    text: "250,000-500,00",
    value: "250,000-500,00",
  },
  {
    key: "500,000-1m",
    text: "500,000-1m",
    value: "500,000-1m",
  },
  {
    key: "1m - 3m",
    text: "1m - 3m",
    value: "1m - 3m",
  },
  {
    key: "Over 3m",
    text: "Over 3m",
    value: "Over 3m",
  },
];

const TripleDropdown: React.FC<IStep1Props> = ({
  question,
  answers,
  role,
  handleAnswerChange,
}: IStep1Props) => {
  const StateId = `${question.uid}_state`;
  const CompanyId = `${question.uid}_company`;
  const ProfileId = `${question.uid}_profile`;

  const handleDropdownChange = (
    dropdown: string,
    newValue: string | number | boolean | (string | number | boolean)[]
  ) => {
    if (typeof newValue === "string") {
      handleAnswerChange(dropdown, newValue);
    }
  };

  const showEmailError = () => {
    const email = answers.userEmail
    return email && email !== "" && !isValidEmail(email.toString())
  }

  return (
    <>
      <LabelText>State:</LabelText>
      <Dropdown
        placeholder="Select"
        fluid
        search
        selection
        options={statesOptions}
        value={answers[StateId]}
        onChange={(e, { value }) => {
          handleDropdownChange(StateId, value);
        }}
      />

      <LabelText>Name:</LabelText>
      <Input
        value={answers.name}
        name="name"
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
        }}
      />

      <LabelText>Email:</LabelText>
      <Input
        name="userEmail"
        autocomplete="off"
        value={answers.userEmail}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
        }}
      />
      {
        <ErrorText>{showEmailError() && 'Email must be formatted correctly.'}</ErrorText>
      }
      <LabelText>{  role == Role.broker ? 'Firm Name:' : 'Company Name:' }</LabelText>
      <Input
        autocomplete={false}
        value={answers.firmName}
        name="firmName"
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
        }}
      />

      <LabelText>{role != Role.broker ?  "Company Size:" : "Average Client Size:"}</LabelText>
      <Dropdown
        placeholder={"Select"}
        fluid
        search
        selection
        options={companySizeOptions}
        value={answers[CompanyId]}
        onChange={(e, { value }) => {
          handleDropdownChange(CompanyId, value);
        }}
      />
    </>
  );
};

export default TripleDropdown;

interface IStep1Props {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
  };
  role: string;
  answers: { [key: string]: string | number };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
}
