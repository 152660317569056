import { Button, ButtonBackground, ButtonContainer } from "./styles";

import React from "react";
import { Role } from "@global/types";

const RoleButton = ({ role, onClick }: RoleButtonProps) => {
  return (
    <ButtonContainer role={role} onClick={onClick}>
      <Button role={role}>
        {role === Role.broker ? "I'm an Advisor" : "I'm an Employer"}
      </Button>
    </ButtonContainer>
  );
};

export default RoleButton;

interface RoleButtonProps {
  role: Role;
  onClick: () => void;
}
