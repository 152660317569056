import {
  CloseButton,
  ModalBackdrop,
  ModalContainer,
  ModalWarning,
  Spinner,
} from "../styles/index";
import React, { useEffect, useState } from "react";

import { COUNTER_API_URL } from "config";
import { CounterAPI } from "counterapi";
import { Layout } from "../components/Layout";
import { PageProps } from "gatsby";
import { Role } from "@global/types";
import RoleSelector from "../components/RoleSelector";
import SEO from "../components/SEO/SEO";
import StepSwitch from "../components/StepSwitch";
import Welcome from "../components/Welcome";
import countapi from "countapi-js";
import { hubspotSubmit } from "../helpers/requests";
import { questionsBroker } from "../questionsBroker";
import { questionsEmployer } from "../questionsEmployer";
import { setTimeout } from "timers";

type DataProps = unknown;

const Dif = "—";

const Index: React.FC<PageProps<DataProps>> = () => {
  const [answers, setAnswers] = useState<IAnswers>({});
  const [currentStep, setCurrentStep] = useState(1);
  const [submitError, setSubmitError] = useState(false);
  const [maxStep, setMaxStep] = useState(-1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [roleSelected, setRoleSelected] = useState(undefined);
  const [questions, setQuestions] = useState(questionsEmployer);
  const [totalSteps, setTotalSteps] = useState(0);

  const currentQuestion = questions[currentStep];

  const currentAnswer = answers[currentQuestion.uid];
  const currentOtherAnswer = answers[currentQuestion.uid + "_other"];

  const validator = (
    answer: string | number | undefined | null,
    currentStep?: number
  ) => {
    if (currentStep === 0) return true;
    if (!answer && !currentOtherAnswer) {
      return false;
    }
    if (currentStep >= questions.length){
      return false;
    } 
    if (typeof answer === "string" && /^\s+$/.test(answer as string)){
      return false;
    }
    if (currentQuestion.minOptions && typeof answer === "string") {
      const answerArray = answer.split(Dif);
      if ((answerArray.length < currentQuestion.minOptions + 1)) {
        return false;
      }
    }
    if (
      currentQuestion.other &&
      typeof currentAnswer === "string" &&
      currentAnswer.includes("Other")
    ) {
      if (!answers[currentQuestion.uid + "_other"]) return false;
    }
    return true;
  };

  const handleRoleSelected = (role: Role) => {
    if (role === Role.employer) {
      setQuestions(questionsEmployer);
      setTotalSteps(questionsEmployer.length - 2);
    }
    if (role === Role.broker) {
      setQuestions(questionsBroker);
      setTotalSteps(questionsBroker.length - 2);
    }
    setRoleSelected(role);
  };

  const handleNext = async () => {
    if (currentStep === questions.length - 2) {
      setShowSpinner(true);
      const responseStatus = await hubspotSubmit(answers, roleSelected);

      if (responseStatus) {
        setCurrentStep((prevStep) => prevStep + 1);
        setShowSpinner(false);
      } else {
        setSubmitError(true);
        window.setTimeout(()=>{ setSubmitError(false); setShowSpinner(false); }, 4000);
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep === 0) return;
    if (currentStep === 1) {
      setRoleSelected(undefined);
      setAnswers({});
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleAnswerChange = (
    questionName: string,
    questionAnswer: string | number
  ) => {
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, [questionName]: questionAnswer };
    });
  };
  const closeErrorModal = () => {
    setSubmitError(false);
  };

  const TrackEvent = (step: number) => {
    const host = window.location.href;
    const env =
      host.includes("wellnet-survey.com") || host.includes("survey.wellnet.com")
        ? ".prod"
        : ".test";
    const role = host.includes("employer") ? "employer" : "broker";
    try {
      const counter = new CounterAPI();
      fetch(COUNTER_API_URL+'/pageview-hit?page='+env+'-'+role+'step-'+currentQuestion.uid).then((res) => {
      })
    } catch (error) {
      console.log('error saving metrics')
    } 

  };

  useEffect(() => {
    if (currentStep > maxStep) {
      TrackEvent(currentStep);
      setMaxStep(currentStep);
    }
  }, [currentStep]);

  return (
    <div>
      <SEO
        ogSiteName={"WellNet Survey"}
        pageTitle={"WellNet Survey"}
        description={"Help us know you better"}
        keywords={"WellNet, wellnet, survey, health"}
        ogType={"website"}
        ogUrl={"/"}
        ogImage={"/seo_images/meta_image.jpg"}
        twitterImage={"/seo_images/meta_image.jpg"}
      />
      <Layout>
        <ModalBackdrop active={showSpinner} key="loadingModal">
          <Spinner />
        </ModalBackdrop>
        <ModalBackdrop active={submitError} key="modal">
          <CloseButton onClick={() => closeErrorModal()}>X</CloseButton>
          <ModalContainer>
            <ModalWarning>We had an unexpected error.</ModalWarning>
            <ModalWarning>Please try submitting the form again.</ModalWarning>
          </ModalContainer>
        </ModalBackdrop>
        {currentStep === 0 && <Welcome handleNext={handleNext} />}
        {currentStep === 1 && !roleSelected && (
          <RoleSelector handleRoleSelected={handleRoleSelected} />
        )}
        {roleSelected && (
          <StepSwitch
            submit={currentStep === totalSteps}
            currentQuestion={currentQuestion}
            currentAnswer={currentAnswer}
            totalSteps={totalSteps}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            handleNext={handleNext}
            handlePrev={handlePrev}
            validator={validator}
            currentStep={currentStep}
            role={roleSelected}
          />
        )}
      </Layout>
    </div>
  );
};

export default Index;

interface IAnswers {
  [key: string]: string | number;
}
