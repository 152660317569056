import {
  AclarationText,
  Background,
  CurrentStep,
  FinalText,
  HeaderContainer,
  HeaderLine,
  QuestionBody,
  QuestionInformationContainer,
  QuestionLayoutContainer,
  QuestionSection,
  QuestionTextContainer,
  StepsCounter,
  StickersImage,
  SurveyLogoContainer,
  ValidationNote,
  WellnetLogoContainer
} from "./styles";
import {
  QuestionContainer,
  QuestionText,
  StepButton,
  StepButtonsContainer,
} from "../../styles/questionCommons";
import React, {useEffect} from "react";

import { Role } from "@global/types";
import Stickers from "@assets/Stickers-broker-2024.png"
import SurveyLogo from "@assets/SurveyLogo2024.png";
import WellnetLogo from "@assets/WellnetLogo";
import { isValidEmail } from "helpers/regex";

const QuestionLayout = ({
  children,
  role,
  currentStep,
  totalSteps,
  question,
  handleNext,
  handlePrev,
  validator,
  submit,
  currentAnswer,
  answers,
  shouldValidate = true
}: QuestionLayoutProps) => {
  let continueButton: string;

  if (handleNext) {
    continueButton = "Next";
  }

  if (submit) {
    continueButton = "Submit";
  }

  const StateId = `${question.uid}_state`;
  const CompanyId = `${question.uid}_company`;
  // const ProfileId = `${question.uid}_profile`;

  const validatorWrapper = () => {
    const result =
      validator(answers[StateId]) &&
      (role == Role.broker ||  validator(answers[CompanyId]))
    return result;
  };
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const isSubmitDisable = () => {
      let disable = false
      if(continueButton === 'Submit'){
        const email = answers['userEmail']
        if(email){
          disable = !isValidEmail(email.toString())
        } else {
          disable = true
        }
        console.log('email', email)
      } else {
      disable = !shouldValidate ? false : !validator(currentAnswer)
      }
      return disable
    }

  return (
    <QuestionLayoutContainer role={role}>
      <QuestionBody>
        <HeaderContainer>
          <WellnetLogoContainer>
            <WellnetLogo />
          </WellnetLogoContainer>
          <HeaderLine />
          <SurveyLogoContainer>
            <img src={SurveyLogo} />
          </SurveyLogoContainer>
        </HeaderContainer>
        <QuestionInformationContainer>
          {currentStep !== totalSteps ?
          (<>
          <StepsCounter>
            <CurrentStep role={role}>{role === Role.broker ? "Advisor" : "Employer"} <span>- </span>Q{currentStep}</CurrentStep>/{totalSteps - 1}
          </StepsCounter>
          </>)
          :
          (<>
            <StepsCounter>
              <FinalText role={role}>Thank You For Contributing To The Survey!</FinalText>
            </StepsCounter>
            </>)
          }
        </QuestionInformationContainer>
        {question.section && (
          <QuestionSection role={role}>{question.section}</QuestionSection>
        )}
        <QuestionContainer>
          <QuestionTextContainer finish={question.type === 'triple dropdown'}>
            <QuestionText>{question.question}</QuestionText>
            {question.aclaration && (
              <AclarationText role={role}>{question.aclaration}</AclarationText>
            )}
          </QuestionTextContainer>
          {children}
          {(question.minOptions && question.minOptions > 1) &&
          <ValidationNote><span>Please Note:</span> To advance from this question, you must select {question.minOptions} choices & click Next.</ValidationNote>
          }
          <StepButtonsContainer>
            {continueButton && (
              <StepButton
                onClick={() =>
                  currentStep === totalSteps
                    ? !shouldValidate ? handleNext() : validatorWrapper() && handleNext()
                    : !shouldValidate ? handleNext() : validator(currentAnswer) && handleNext()
                }
                disabled={isSubmitDisable()}
                role={role}
              >
                {continueButton}
              </StepButton>
            )}
            
            {handlePrev && (
              <StepButton transparent role={role} onClick={handlePrev}>
                Back
              </StepButton>
            )}
          </StepButtonsContainer>
        </QuestionContainer>
      </QuestionBody>
          <StickersImage src={Stickers} />
          <Background role={role}/>
    </QuestionLayoutContainer>
  );
};

export default QuestionLayout;

interface QuestionLayoutProps {
  children: JSX.Element;
  role: Role;
  currentStep: number;
  totalSteps: number;
  shouldValidate?: boolean,
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
    aclaration?: string;
  };
  handleNext?: () => void;
  handlePrev?: () => void;
  validator: (
    answer: string | number | undefined | null,
    currentStep?: number
  ) => boolean;
  currentAnswer: string;
  submit: boolean;
  answers?: { [key: string]: string | number };
}
