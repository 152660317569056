import { Role } from "@global/types";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 240px;
  height: 81px;
  position: relative;
  margin-top: 30px;
  transition: 0.2s;
  @media (max-width: 1045px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const Button = styled.div<ButtonProps>`
  color: white;
  width: 100%;
  height: 71px;
  z-index: 100;
  position: absolute;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  background-color: ${({ role }) => (role === Role.broker ? "#5462F3" : "#F200FF")};
  cursor: pointer;
  transition: all 0.2s;
  &:hover{
    background-color: ${({ role }) => (role === Role.broker ? "#4727D8" : "#B322F3")};
  }
`;

export const ButtonBackground = styled.div<ButtonProps>`
  background: ${({ role }) => (role === Role.broker ? "#3EB0FF" : "#F000FF")};;
  width: 100%;
  height: 71px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  cursor: pointer;
`;

interface ButtonProps {
  role: Role;
}
