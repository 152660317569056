import {
  Input as SemanticInput,
  TextArea as SemanticTextArea,
} from "semantic-ui-react";

import { Role } from "@global/types";
import styled from "styled-components";

export const QuestionContainer = styled.div`
  color: white;
  border-radius: 20px;
  width: 75vw;
  max-width: 850px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 85vw;
  }
  @media (max-width: 450px) {
    width: 95vw;
  }
`;
export const Step = styled.p`
  font-weight: 200;
  font-size: 2rem;
  @media (max-width: 450px) {
    font-size: 1.2rem;
  }
`;

export const QuestionText = styled.p`
  font-weight: 600;
  text-align: start;
  font-size: 18px;
  margin-top: 30px;
  white-space: pre-line;
  line-height: 28px;
  max-width: 580px;
  text-align: left;
  width: 100%;
`;
export const LabelText = styled(QuestionText)`
  margin-bottom: 7px;
`;

export const ErrorText = styled(QuestionText)`
  margin-bottom: 7px;
  margin-top: 5px;
  font-size: 14px;
  color: #ff4c4c;
  font-weight: 500;
`;

interface ILableTextProps {
    noMarginTop?: boolean
}

export const TextArea = styled(SemanticTextArea)`
  background: transparent !important;
  border: 1px solid white !important;
  font-family: "Montserrat" !important;
  color: white !important;
  font-weight: 500 !important;
  transition: 0.5s !important;
  float: left;
  & ::placeholder {
    color: white !important;
    font-weight: 100 !important;
  }
  & :focus {
    box-shadow: 0 0 5px white !important;
  }

  &[disabled] {
    background: rgba(170, 170, 170, 0.2) !important;
    color: gray !important;
    & ::placeholder {
      color: gray !important;
    }
    border-color: lightgray !important;
    filter: brightness(0.9);
  }
`;
export const Input = styled(SemanticInput)`
  input {
    background: transparent !important;
    border: 1px solid white !important;
    font-family: "Montserrat" !important;
    color: white !important;
    font-weight: 500 !important;
    & ::placeholder {
      color: white !important;
      font-weight: 100 !important;
    }
    & :focus {
      box-shadow: 0 0 5px white !important;
    }
  }
`;

export const StepButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  margin: 30px 0 0 0;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
export const StepButton = styled.button<StepButtonProps>`
  background: ${({ transparent }) => (transparent ? "transparent" : "white")};
  cursor: pointer;
  border: 2px solid white;
  border-color:  white;
  width: auto;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 1rem;
  color: ${({ transparent, role }) => (transparent ? "white" : (role === Role.broker ? "#0027AC" : "#A81BEA"))};
  width: auto;
  min-width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 0 0;
  transition: all 0.2s;
  @media (max-width: 768px) {
    margin: 0 !important;
  }

  & :hover {
    ${({ transparent, role }) =>
    `
    font-weight: 600;
    color: ${ role === Role.broker && !transparent ? '#0027AC' : 'white'};
    border-color: ${transparent ? "white" : role === Role.broker ? "#94D9FF" : "#A81BEA"};
    ${
      transparent
        ? `border-color: ${role === Role.broker ? "#88E9FF" : "#A81BEA"};`
        : `background: ${role === Role.broker ? "#94D9FF" : "#A81BEA"};`
      }
    `}
  }

  ${({disabled}) => disabled && `
  background: #C1C1C1 !important;
  border-color: #C1C1C1 !important;
  color: #747474 !important;
  `}
`;

interface StepButtonProps {
  transparent?: boolean;
  disabled?: boolean;
  role?: Role;
}
