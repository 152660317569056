import { Role } from "@global/types";
import { Checkbox as SemanticCheckbox } from "semantic-ui-react";
import styled from "styled-components";

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  max-width: 671px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  & .options {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 3;
    div {
      display: flex;
      max-width: 800px;
      label {
        text-align: start;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
  & .otherOption {
    .checkbox {
      display: flex;
    }
    flex-grow: 3;
    flex: 2;
  }
`;

export const Checkbox = styled(SemanticCheckbox)<{ role: Role }>`
  margin-bottom: 15px;
  & :hover {
    & label {
      & ::before {
        box-shadow: 0 0 5px 2px white !important;
      }
    }
  }
  & label {
    color: white !important;
    width: fit-content !important;
    font-weight: 500 !important;
    & ::before {
      border-radius: 500rem !important;
      background: transparent !important;
      border: 1px solid
        ${({ role }) => (role === Role.broker ? "#94D9FF" : "#D8A6FF")} !important;
      width: 20px !important;
      height: 20px !important;
    }
    & ::after {
      invert: none !important;
      content: "" !important;
      border-radius: 500rem;
      background: ${({ role }) =>
        role === Role.broker ? "#94D9FF" : "#D8A6FF"} !important;
      top: 4px !important;
      left: 4px !important;
      width: 12px !important;
      height: 12px !important;
    }
  }
`;
