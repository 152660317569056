import Finish from "../Finish";
import LongTextInput from "../LongTextInput";
import MultipleOption from "../MultipleOption";
import QuestionLayout from "@components/QuestionLayout";
import RankedOption from "../RankedOption";
import React from "react";
import { Role } from "@global/types";
import SingleOption from "../SingleOption";
import TextInput from "../TextInput";
import TripleDropdown from "../TripleDropdown";
import Welcome from "../Welcome";

const StepSwitch: React.FC<IStepSwitchProps> = ({
  totalSteps,
  currentStep,
  submit,
  currentQuestion,
  currentAnswer,
  answers,
  handleAnswerChange,
  handleNext,
  handlePrev,
  validator,
  role,
}: IStepSwitchProps) => {
  switch (currentQuestion.type) {
    case "welcome":
      return <Welcome handleNext={handleNext} />;
    case "text":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={submit}
          handleNext={handleNext}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
        >
          <TextInput
            key={currentQuestion.uid}
            currentAnswer={currentAnswer as string}
            handleAnswerChange={handleAnswerChange}
            question={currentQuestion}
          />
        </QuestionLayout>
      );
    case "long text":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={submit}
          handleNext={handleNext}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
        >
          <LongTextInput
            key={currentQuestion.uid}
            currentAnswer={currentAnswer as string}
            handleAnswerChange={handleAnswerChange}
            question={currentQuestion}
          />
        </QuestionLayout>
      );
    case "single option":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={submit}
          handleNext={handleNext}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
        >
          <SingleOption
            key={currentQuestion.uid}
            question={currentQuestion}
            currentAnswer={currentAnswer as string}
            handleAnswerChange={handleAnswerChange}
            role={role}
          />
        </QuestionLayout>
      );
    case "multiple option":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={submit}
          handleNext={handleNext}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
        >
          <MultipleOption
            key={currentQuestion.uid}
            question={currentQuestion}
            currentAnswer={currentAnswer as string}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            role={role}
          />
        </QuestionLayout>
      );
    case "triple dropdown":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={true}
          handleNext={handleNext}
          shouldValidate={false}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
          answers={answers}
        >
          <TripleDropdown
            key={currentQuestion.uid}
            question={currentQuestion}
            answers={answers}
            role={role}
            handleAnswerChange={handleAnswerChange}
          />
        </QuestionLayout>
      );
    case "ranked option":
      return (
        <QuestionLayout
          role={role}
          totalSteps={totalSteps}
          currentStep={currentStep}
          question={currentQuestion}
          submit={submit}
          handleNext={handleNext}
          handlePrev={handlePrev}
          validator={validator}
          currentAnswer={currentAnswer as string}
        >
          <RankedOption
            key={currentQuestion.uid}
            question={currentQuestion}
            currentAnswer={currentAnswer as string}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            role={role}
          />
        </QuestionLayout>
      );
    case "finish":
      return <Finish role={role}/>;
    default:
      return <div>error screen</div>;
  }
};

export default StepSwitch;

interface IStepSwitchProps {
  submit: boolean;
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  handleNext: () => void;
  handlePrev: () => void;
  validator: (
    answer: string | number | undefined | null,
    currentStep?: number
  ) => boolean;
  currentQuestion: {
    uid: string;
    type: string;
    question: string;
    options?: string[];
    other?: boolean;
    maxOptions?: number;
  };
  currentStep: number;
  totalSteps: number;
  currentAnswer: string | number;
  answers: { [key: string]: string | number };
  role: Role;
}
