import styled from "styled-components";
import { Button as SemanticButton } from "semantic-ui-react";
import mainBG from "../../assets/BG-main.png";
import plus from "../../assets/plus-orange.png";

export const WelcomeContainer = styled.div`
  background-image: url(${plus}), url(${mainBG});
  background-repeat: no-repeat, no-repeat;
  background-origin: border-box;
  background-size: 100px auto, cover;
  background-position: bottom 10% right 5%, top left;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::before {
    content: "";
    width: 6vw;
    height: 6vw;
    background: url(${plus});
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    opacity: 0.6;
    bottom: 50%;
    left: 10%;
    position: absolute;
  }
  &::after {
    width: 7vw;
    height: 7vw;
    content: "";
    background: url(${plus});
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    opacity: 0.3;
    top: 10%;
    right: 15%;
    position: absolute;
    @media (min-width: 1024px) {
      width: 50px;
      height: 50px;
      top: 1%;
    }
  }
  @media (min-width: 1024px) {
    background-size: 12% auto, cover;
    background-position: bottom 30% right -2%, top left;
  }
  @media (max-width: 740px) {
    background-position: bottom 30% right 5%, top left;
    height: 100%;
  }
  @media (max-width: 460px) {
    background-position: bottom 40% right 2%, top left;
  }
`;

export const MainWelcome = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoImage = styled.img`
  width: 30vw;
  min-width: 200px;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
  @media (max-width: 425px) {
    width: 60vw;
  }
`;

export const Button = styled(SemanticButton)`
  color: white !important;
  width: fit-content !important;
  margin: 10px auto !important;
  background: none !important;
  border-radius: 0 !important;
  border: 2px solid white !important;
  padding: 15px 60px !important;
  font-family: "Montserrat" !important;
  transition: 0.2s;
  @media (min-width: 1600px) {
    margin: 20px auto !important;
    font-size: 2rem !important;
  }
  & :hover {
    background: rgba(255, 255, 255, 0.45) !important;
  }
  & :focus {
    background: rgba(255, 255, 255, 0.45) !important;
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 3rem;
  margin: 45px auto 20px auto;
  font-weight: 300;
  @media (min-width: 1600px) {
    font-size: 5vh;
  }
  @media (max-width: 740px) {
    width: 80vw;
  }
  @media (max-width: 460px) {
    font-size: 1.7rem;
  }
`;

export const SubTitle = styled.p`
  color: white;
  margin: 15px auto;
  font-weight: 800;
  font-size: 24px;
  @media (min-width: 1600px) {
    font-size: 1.8rem;
  }
  @media (max-width: 740px) {
    max-width: 80vw;
  }
`;

export const RemarkableSection = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 740px) {
    flex-direction: column;
    align-self: center;
    padding: 40px 0;
  }
`;

export const TabletImage = styled.img`
  width: auto;
  height: 40vh;
  min-height: 200px;
  max-height: 600px;
  margin: 0;
  @media (max-width: 740px) {
    width: 55vw;
    height: auto;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    width: 80vw;
    height: auto;
  }
`;

export const TextSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RemarkableTitle = styled.img`
  width: 20vw;
  min-width: 200px;
  max-width: 600px;
  height: auto;
  margin: 0 0 20px;
  @media (max-width: 740px) {
    width: 50vw;
    margin: 0 auto 20px;
  }
  @media (max-width: 425px) {
  }
`;

export const RemarkableText = styled.p`
  width: 30vw;
  padding-bottom: 10px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  @media (min-width: 1600px) {
    font-size: 1.5rem;
  }
  @media (max-width: 740px) {
    width: 80vw;
    padding-bottom: 0;
    text-align: center;
  }
`;

export const Bold = styled.span`
  font-weight: 800;
  color: #1bcafd;
`;

export const Italic = styled.span`
  font-style: italic;
  color: white;
`;
