import React from "react";
import { Input } from "../../styles/questionCommons";

const TextInput: React.FC<TextInputProps> = ({
  currentAnswer,
  handleAnswerChange,
  question,
}) => {
  return (
    <Input
      key={question.uid}
      type="text"
      name={question.uid}
      value={currentAnswer}
      onChange={(e: React.FormEvent<HTMLInputElement>) => {
        handleAnswerChange(e.currentTarget.name, e.currentTarget.value);
      }}
      maxlength="150"
    />
  );
};

export default TextInput;

interface TextInputProps {
  question: {
    uid: string;
    type: string;
    section?: string;
    question: string;
  };
  handleAnswerChange: (
    questionName: string,
    questionAnswer: string | number
  ) => void;
  currentAnswer: string;
}
