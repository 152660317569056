import { Role } from "@global/types";
import axios from "axios";

export const hubspotSubmit = async (
  submitData: ISubmitData,
  formRole: string
): Promise<boolean> => {
  const randomEmail = 'survey-response' + Date.now().toString() + "@wellnet-survey.com";

  const hubspotFieldsDataParser = (data: ISubmitData): IField[] => {
    const objectKeys = Object.keys(submitData);
    const objectValues = Object.values(submitData);

    let fields: IField[] = [];

    for (let i = 0; i < objectKeys.length; i++) {
      fields.push({
        name: objectKeys[i],
        value: objectValues[i],
      });
    }

    return fields;
  };

  try {
    // ============ Sending to Hubspot ============ //
    const PORTAL_ID = '46467936';
    const host = window.location.href;
    const env =
    host.includes("wellnet-survey.com") || host.includes("survey.wellnet.com")
      ? ".prod"
      : ".test";

    const PROD_FORMS = '57c7c5db-6b6c-4a45-afd1-aa9681569dc0,65fcaea5-69aa-45e6-9d1e-74be2d29068a';
    const TEST_FORMS = '04ad8182-ca4c-4f94-a5cf-44b0b3d11735,44784b4a-8f64-48ec-8beb-17fe5e27cd0c';
    const FORM_ID_LIST = env === ".prod" ? PROD_FORMS : TEST_FORMS
    const FORM_ID_LIST_ARRAY = FORM_ID_LIST.split(",");
    const FORM_ID =
      formRole === Role.broker ? FORM_ID_LIST_ARRAY[0] : FORM_ID_LIST_ARRAY[1];

    const response = await axios.request({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
      data: JSON.stringify({
        fields: [
          {
            name: "email",
            value: randomEmail,
          },
          {
            name: "job_function",
            value: formRole,
          },
          {
            name: "message",
            value: JSON.stringify(submitData),
          },
        ],
      }),
    });
    console.log(response);
    return true;
  } catch (error) {
    return false;
  }
};

interface ISubmitData {
  [key: string]: string | number;
}

interface IField {
  name: string;
  value: string | number;
}
